import { createAction } from "redux-actions";
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  COMPLETE_SIGNUP_SUCCESS,
  COMPLETE_SIGNUP_FAILURE,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
} from "../constants/actionTypes";
import * as Authentication from "../api/authentication";

const signupSuccess = createAction(SIGNUP_SUCCESS);
const signupFailure = createAction(SIGNUP_FAILURE);

export function signup(params) {
  return (dispatch) =>
    Authentication.signup(params)
      .then(({ data }) => dispatch(signupSuccess({ data })))
      .catch((error) => dispatch(signupFailure(error)));
}

const completeSignupSuccess = createAction(COMPLETE_SIGNUP_SUCCESS);
const completeSignupFailure = createAction(COMPLETE_SIGNUP_FAILURE);

export function completeSignup(params) {
  return (dispatch) =>
    Authentication.completeSignup(params)
      .then(({ data }) => dispatch(completeSignupSuccess({ data })))
      .catch((error) => dispatch(completeSignupFailure(error)));
}

const signinSuccess = createAction(SIGNIN_SUCCESS);
const signinFailure = createAction(SIGNIN_FAILURE);

export function signin(params) {
  return (dispatch) =>
    Authentication.signin(params)
      .then(({ data }) => dispatch(signinSuccess({ data })))
      .catch((error) => dispatch(signinFailure(error)));
}

export const loginUser = (user, history, callback) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, callback },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  };
};

export const registerUserSuccessful = (user) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  };
};

export const registerUserFailed = (user) => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  };
};
