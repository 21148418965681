import ajax from "./ajax";

export function signup(data) {
  return ajax(`/onboarding/signup`, { method: "POST", data });
}

export function completeSignup(data) {
  return ajax(`/onboarding/complete-signup`, { method: "POST", data });
}

export function signin(data) {
  return ajax(`/onboarding/signin`, { method: "POST", data });
}
