import React, { Suspense, lazy, useEffect } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// import VerticalLayout from "./Layouts/VerticalLayout/";
import NonAuthLayout from "./Layouts/NonAuthLayout";
import AppRoute from "../routes/routes";
import {
  // authProtectedRoutes,
  publicRoutes,
} from "../routes/";
import "../assets/scss/theme.scss";
import PreLoader from "./common/PreLoader";
import { setGlobalAlert } from "../actions/common";
import Snackbar from "./common/Snackbar";
import { CircularProgress } from "@mui/material";
import ErrorBoundary from "./ErrorBoundary";
import { requestTheme } from "../actions/settings";
import { CONSUMER_KEY } from "../constants/common";

const Pages404 = lazy(() => import("../pages/static/404"));

const App = () => {
  const dispatch = useDispatch();

  const globalAlert = useSelector((state) => state?.common?.alert);
  const circularProgress = useSelector(
    (state) => state?.common?.circularProgress
  );

  useEffect(() => {
    dispatch(requestTheme({ consumer_key: CONSUMER_KEY }));
  }, [dispatch]);

  const theme = createTheme({
    typography: {
      fontFamily: ["Vastago Grotesk", "sans-serif"].join(","),
      h5: {
        fontFamily: ["Vastago Grotesk", "sans-serif"].join(","), // Specific to h5
      },
      h6: {
        fontFamily: ["Vastago Grotesk", "sans-serif"].join(","), // Specific to h6
      },
      body1: {
        fontFamily: ["Vastago Grotesk", "sans-serif"].join(","), // Specific to body
      },
    },
  });

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          {/* <AllInitialApiCallsComponent /> */}

          <Router>
            <Suspense fallback={<PreLoader />}>
              <Switch>
                {publicRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                  />
                ))}

                {/* {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={VerticalLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact={route.exact}
                />
              ))} */}

                <Route component={Pages404} />
              </Switch>
            </Suspense>
          </Router>

          {circularProgress && (
            <CircularProgress className="in-the-center-and-on-top-of-all" />
          )}

          {!isEmpty(globalAlert) && (
            <Snackbar
              alert={globalAlert}
              onClose={() => dispatch(setGlobalAlert({}))}
              open={!isEmpty(globalAlert)}
            />
          )}
        </React.Fragment>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
